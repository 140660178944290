// Breakpoints
$xs: 360px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1680px;

// Spacers
$base: 0.9rem;
$spacer1: $base * 2;
$spacer2: $spacer1 * 2;
$spacer3: $spacer2 * 2;
$spacer4: $spacer3 * 2;

// Variables
$bg: #E5E5E5;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;