@import "./Fonts.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 7px;
  font-weight: 300;
  @media screen and (min-width: $sm) {
    font-size: 8px;
  }
  @media screen and (min-width: $md) {
    font-size: 9px;
  }
}

body {
  font-family: "NB International Pro", Arial, sans-serif;
  font-weight: 500;
  font-size: 3rem;
  line-height: 1.5;
  letter-spacing: 0.02rem;
}

h1 {
  font-size: 5vw;
}

h1,
.sidebar p {
  font-family: "Neue Plak Extended";
}

h2 {
  font-family: "Neue Plak Extended";
  font-size: 3vw;
  line-height: 3.5vw;
}

h3 {
  font-family: "Neue Plak Extended";
  font-size: 3.2rem;
  line-height: 3.6rem;
}
h4 {
  font-family: "Neue Plak Extended";
  font-size: 2.4rem;
  line-height: 2.8rem;
}

h6 {
  font-size: 2rem;
}
.description {
  font-size: 2rem;
}

.topic-title {
  font-size: 2.1rem;
  font-weight: 600;
  font-family: "Neue Plak Extended";
}

.showcase-img {
  width: 260px;
  height: 260px;
}

.team-members {
  font-size: 1.4rem;
  font-family: "NB International Pro";
}

.team-name {
  font-size: 2.1rem;
  font-family: "NB International Pro";
}

.apply {
  font-size: 0;
}

#apply span {
  font-size: 3rem;
}

.sidebar p {
  font-size: 1.6rem;
}

.landing span {
  font-family: "NB International Pro", Arial, sans-serif;
  font-size: 1.6vw;
  padding: 0 2rem;
  font-weight: 500;
}

.gradient span {
  font-family: "NB International Pro", Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
}

.navHeader h3,
.nav span {
  font-family: "NB International Pro", Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
}

.navHeader h3 {
  text-transform: uppercase;
}

.nav a {
  font-size: 1.8rem;
}

.overviewDetails li p {
  font-family: "NB International Pro", Arial, sans-serif;
  font-weight: 500;
  font-size: 3vw;
}

.counter::before {
  font-family: "Neue Plak Extended";
}

.outcome li::before {
  font-family: "NB International Mono";
  font-size: 3.5vw;
}

.outcome div:last-child {
  font-size: 3vw;
}

.q {
  font-family: "Neue Plak Extended";
}
