@import "./Fonts.scss";

@media only screen and (max-width: $sm) {
  body {
    font-size: 4vw;  
  }

  h1 {
    font-size: 7vw;
  }

  h2 {
    font-size: 6vw;
    line-height: 7vw;;
  }
  
  .landing span {
    font-size: 4.6vw;
  }

  #levelupHeader {
    font-size: 4.1vw;
  }

  .nav span {
    font-size: 5vw;
  }   

  .overviewDetails li p {
    font-size: 4vw;
  }

  .outcome {
    li::before {
      font-size: 6.5vw;
    }
  }

  #email {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: $lg) and (min-width: $sm + 1) {
  .navHeader h3, .nav span {
    font-size: 1.6vw;
  }

  .landing {
    #apply {
      span {
        font-size: 2vw;

        img {
          width: 2vw;
          margin-left: 1.5vw;
        }
      }
    }
  }
}

@media screen and (max-width: $xxxl) and (min-width: $sm + 1) {
  body {
    font-size: 2vw;

    .q {
      &:before {
        top: 1.2vw;
      }
    }
  }
}