@import "./Reset.scss";
@import "./Constants.scss";
@import "./Typography.scss";
@import "./Mixins.scss";
@import "./Mobile.scss";
@import "./MobileTypography.scss";

// Custom Cursor
body {
  // cursor: url(../images/cursor.png), auto;
  z-index: -100;
}

#landing.landing {
  cursor: auto;
}

#nav {
  cursor: auto;
}

@media (max-width: 550px) {
  .full-screen-menu {
    background-color: $bg;
    height: 100%;
    width: 100%;
    left: 0px;
  }
}

.sidebar {
  cursor: auto;
}

/************************* BASE ***************************/

html {
  overflow-x: hidden;
}

body,
html {
  background-color: $bg;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  counter-reset: responsibility;
  overflow: visible;
}

.sidebar {
  width: 7vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  border-right: 1px solid black;
  background-color: transparent;
  z-index: 1;

  p {
    @include transform(rotate(-90deg));
    width: max-content;
    margin: 0;
    text-transform: uppercase;
  }

  img {
    width: 4rem;
  }
}

#gradientCircle {
  width: 800px;
  height: 800px;
  background-image: url("../images/gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: var(--x);
  top: var(--y);
  z-index: -75;
  cursor: auto;
}

.container {
  margin-left: 7vw;
  width: 93vw;
  transition: 0.3s;
}

.gradientTransition {
  text-align: center;
  border: solid;
  border-width: 0 1px 1px 1px;
  position: relative;
  height: 8rem;
  line-height: 8rem;
  overflow: hidden;

  span {
    position: relative;
    z-index: 2;
    pointer-events: none;
  }

  img {
    width: 1.8rem;
    margin-left: 2rem;
    vertical-align: middle;
  }

  .column {
    float: left;
    width: 33.33%;
    text-align: center;
  }

  .row {
    padding: 10% 10%;
  }

  .apply {
    height: 8rem;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    background: linear-gradient(90deg, $bg 0%, $bg 100%);
    transform: translateX(0);
    transition: 0.5s linear;

    &:hover,
    &.active {
      transform: translateX(100%);
      transition: 0.5s linear;

      &::after {
        // Prevent jumping on hover
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        height: 8rem;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

.gradient {
  background: linear-gradient(
    90deg,
    #f08e34 0%,
    rgba(52, 240, 57, 0.4),
    transparent
  );
}

.hidden {
  display: none;
}

/************************* NAV ***************************/
.nav {
  position: fixed;
  z-index: 3;
  right: 5rem;
  bottom: 0;
  width: 40rem;
  height: auto;
  border: 1px solid black;
  background-color: $bg;
  transition: 0.3s;
  box-sizing: border-box;

  .gradient {
    border: none;
    opacity: 1;
  }

  @media only screen and (min-width: 600px) {
    .websitecontent-title {
      border-top: 1px solid black;
    }
  }

  @media only screen and (min-width: 600px) {
    .removed-border-bottom {
      border-bottom: 0px solid black !important;
    }
  }

  .gradientTransition {
    text-align: inherit;
    height: calc(1.8rem + 5rem);
    line-height: calc(1.8rem + 5rem);
    border-bottom: 1px solid black; //changed
    overflow: hidden;

    span {
      padding: 2rem 4rem;
    }

    a {
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 2rem 4rem;
      text-decoration: none;
      color: inherit;
      transition: 0.2s linear;
    }
  }
}

.navHeader {
  margin: 0 4rem;
  height: calc(1.8rem + 5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;

  #minimizeBtn {
    cursor: pointer;
    padding: 1rem;
  }
}

.hamburger {
  position: absolute;
  left: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: black;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 20px 0 0;
  overflow: visible;
}

.hamburger-box {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 4rem;
  height: 0.2rem;
  background-color: inherit;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

.hamburger--spin {
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    background-color: black;

    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
        background-color: black;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
          transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        background-color: black;
      }
    }
  }
}

/************************* LANDING ***************************/
.landing {
  position: relative;
  height: 100vh;
  height: var(--windowHeight);
  padding: 8rem 13rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: -50;

  h1 {
    position: relative;
  }

  #apply {
    span {
      border: 1px solid black;
      border-radius: 50%;
      padding: 1.5vw 2vw;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    img {
      width: 2.5rem;
      margin-left: 2rem;
      vertical-align: middle;
    }
  }
}

.landingEllipse {
  position: absolute;
  top: -1.8vw;
  left: -2.5vw;
  width: 26vw;
  height: 13vw;
  pointer-events: none;
}

/************************* OVERVIEW ***************************/
.overview {
  position: relative;
  display: grid;
  grid-template-columns: 35% 10% 55%;
  grid-template-rows: auto;
  background-color: #000;
  color: #fff;
  padding: 5vw 8vw;
  box-sizing: border-box;
}

.overviewTitle {
  width: 75%;
}

.overviewArrow {
  ul {
    li:nth-child(1) {
      img {
        margin-bottom: 3.5vw;
      }
    }
    li:nth-child(2) {
      img {
        margin-bottom: 7vw;
      }
    }
    li:nth-child(3) {
      img {
        margin-bottom: 3.5vw;
      }
    }
    li:nth-child(4) {
      img {
        margin-bottom: 7vw;
      }
    }
  }

  li {
    display: grid;
  }

  img {
    width: 3vw;
    pointer-events: none;
    display: block;
  }
}

.overviewDetails li p {
  margin-bottom: 3vw;
  line-height: 3.5vw;
}

.carousel .slide {
  background: #e5e5e5 !important;
}

/************************* ABOUT ***************************/
.about {
  padding: 5vw 8vw;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;

  .aboutDetails {
    display: grid;
    grid-template-columns: 60% 40%;

    img {
      width: 55%;
      justify-self: center;
      margin-top: -5vw;
    }
  }
}

/************************* WHO ***************************/
.who {
  padding: 5vw 8vw;
}

/************************* RESPONSIBILITY ***************************/
h2 {
  position: relative;
}

.responsibilityEllipse {
  position: absolute;
  top: -2.5vw;
  left: -1.5vw;
  width: 20vw;
  height: 10vw;
  pointer-events: none;
}

.responsibility {
  position: relative;
  display: grid;
  grid-template-columns: 100%/3 100%/3 100%/3;
  grid-auto-rows: 1fr;
  grid-template-areas:
    "header header one"
    "two three four";

  div:not(:first-child) {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2.5vw;
    box-sizing: border-box;
  }
}

.header {
  grid-area: header;
  background-color: #000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one {
  grid-area: one;
}
.two {
  grid-area: two;
}
.three {
  grid-area: three;
}
.four {
  grid-area: four;
}

.counter::before {
  counter-increment: responsibility;
  content: "+" counter(responsibility);
}

/************************* OUTCOME ***************************/
.outcome {
  border: solid;
  border-width: 0 1px 1px 0;

  h2 {
    width: 75%;
  }

  div {
    width: 50%;
    display: table-cell;
    box-sizing: border-box;
  }

  div:first-child {
    padding: 5vw 3vw 5vw 8vw;
  }

  div:last-child {
    background-color: #000;
    color: #fff;
    padding: 5vw 8vw;
    position: relative;
    overflow-x: hidden;

    p {
      position: absolute;
      width: calc(100% - 2 * 8vw);
      bottom: 5vw;
      display: flex;
      line-height: 3.5vw;

      img {
        display: flex;
        height: 4vw;
        margin: 0 2vw 0 -5vw;
      }
    }

    & > img {
      position: absolute;
      right: -5%;
      top: 0;
      width: 60%;
      height: 80%;
    }
  }

  li {
    margin-bottom: $spacer2;
    display: inline-flex;
    vertical-align: text-top;

    &::before {
      content: "*";
      margin: calc(-1.4vw / 2) 2rem 0 calc(-2.5vw - 2rem);
    }
  }
}

/*************** Meet the Level Uppers ******************/

.meetTitle {
  position: relative;
  grid-template-columns: 35% 10% 55%;
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 1.5rem 8vw;
  box-sizing: border-box;
}

/************************* FAQ ***************************/
.faq {
  padding: 5vw 8vw;
  border: solid;
  border-width: 0 1px 1px 0;
}

.faqDetails {
  display: grid;
  grid-template-columns: calc(40% + 3vw) calc(20% - 6vw) calc(40% + 3vw);
}

.faq-item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: $spacer2;
  }

  p {
    margin: 0; // Prevent jump on drawer open
    padding: 0;
  }
}

.faq-title:hover {
  opacity: 0.6;
  cursor: url(../images/cursor.png), auto;
}

.q {
  cursor: pointer;
  margin-bottom: $spacer1;
  transition: all 0.3s ease;

  &:before {
    content: "";
    width: 1vw;
    height: 1vw;
    position: absolute;
    top: 1.5rem;
    left: calc(-2.5vw - 1rem);
    background-image: url("../images/qa.svg");
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    transition: 0.3s;
  }

  &.active:before {
    transform: rotate(45deg);
  }
}

.a-show,
.a-hidden {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.3s ease;
}

.a-show {
  max-height: fit-content;
}

ol {
  list-style-type: decimal;
}

/************************* THANKYOU ***************************/
.thankYou {
  position: relative;
  display: grid;
  grid-template-columns: 35% 10% 55%;
  grid-template-rows: 7vw;
  background-color: #000;
  color: #fff;
  padding: 5vw 8vw;
  box-sizing: border-box;
}

/************************* FOOTER ***************************/
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5vw 8vw;
  border: solid;
  border-width: 0 1px 1px 0;

  img {
    display: inline-block;
    margin-left: 2rem;
  }

  a {
    color: inherit;
  }
}

.signature {
  display: flex;
  align-items: center;

  span {
    display: flex;
  }
}

/*********************/

.team-modal {
  position: fixed;
  top: 0;
  background: black;
  color: white;
  left: 6vw;
  right: 0;
  z-index: 10000;
  overflow-y: scroll;
  max-height: 100%;

  img {
    width: 100%;
  }

  .description {
    margin-top: $spacer1;
  }

  .main-img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .back-arrow {
    width: 30px;
    transition: 0.4s all;
    cursor: pointer;

    &:hover {
      transform: scale(0.8);
    }
  }

  .case-study-button {
    margin-left: auto;
    transition: 0.4s all;
    width: 100% !important;
    height: 100% !important;

    &:hover {
      transform: scale(0.9);
    }
  }

  .inner-modal {
    margin: $spacer3 0;
  }

  p {
    margin-top: $spacer1/2;
  }

  .inner-person {
    margin-top: $spacer2;
  }

  h3 {
    margin-top: $spacer3;
  }

  .top-buttons {
    margin-bottom: $spacer1;
  }
}

.showcase {
  padding: 5vw 8vw;

  h3 {
    margin: $spacer3 0 $spacer1 0;
  }
}

.showcase-div {
  .showcase-img {
    width: 100%;
    height: auto;
    border: 1px solid black;
    margin-top: $spacer2;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    cursor: pointer;
  }

  .showcase-img:hover {
    filter: none;
  }

  .gradient-fill {
    @extend .gradient;
    width: 100%;
    margin-top: -14px;
    height: 2rem;
    border: 1px solid black;
    z-index: 1000;
    margin-bottom: $spacer2;
  }
}

.nav-arrows {
  width: 100%;
  cursor: pointer;
  transition: 0.5s all;
  margin-bottom: 100px;

  &:hover {
    transform: scale(0.9);
  }
}

.carousel-team {
  background: $bg;
  text-align: left;

  img {
    width: 100%;
  }

  h2 {
    padding-top: $spacer2;
    margin-bottom: $spacer1/2;
  }
}

.carousel-main {
  width: 100%;
  display: flex;
}
