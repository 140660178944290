@import "./Constants.scss";

$sidebar: 16vw;

@media only screen and (max-width: $sm) {
  body {
    #gradientCircle {
      width: 100vw - $sidebar;
      height: 100vw - $sidebar;
      top: calc(25% - 16vw);
      left: $sidebar;
    }
  }

  body .sidebar {
    height: calc(100% - 16vw);
    width: $sidebar;
    top: initial;
    bottom: 0;
    z-index: 1;
    border: solid;
    border-width: 1px 1px 0 0;
  }

  #levelupHeader {
    display: block;
    background-color: $bg;
    position: fixed;
    z-index: 3;
    top: 0;
    left: $sidebar;
    transform: rotate(0);
    width: calc(100vw - 16vw);
    height: 16vw;
    text-align: center;
    padding: $sidebar / 2 - 4.1vw;
    border: solid;
    border-width: 0 0 1px 1px;
    box-sizing: border-box;
  }

  #levelup, #summer2020 {
    display: none;
  }

  #nav {
    z-index: 0;
    top: 0;
    left: 16vw;
    width: 100vw;
    height: 100vh;
    height: var(--windowHeight);
    background-color: transparent;
    transform: translate(0);
    border: none;
    border-left: 1px solid black;

    .hamburger {
      left: calc(-16vw / 2 - 2rem);
      top: calc(16vw / 2 - 2rem);
    }

    .navHeader {
      visibility: hidden;
      height: $sidebar;
      margin: 0;
    }

    div:nth-child(3) {
      border-top: none;
    }

    div.gradient {
      width: calc(100vw - 16vw);
      height: calc((var(--windowHeight) - 16vw) / 5);
      border-right: 1px solid black;
      opacity: 0;

      &::before {
        content: "";
        width: calc(100vw - 16vw);
        height: calc((var(--windowHeight) - 16vw) / 5);
        background-color: $bg;
        display: block;
        position: absolute;
        z-index: -1;
      }

      span {
        display: table-cell;
        vertical-align: middle;
        height: calc((var(--windowHeight) - 16vw) / 5 - 3.9rem);
      }
    }

    .gradientTransition a {
      z-index: 0;

      &:hover, &.active {
        &::after {
          height: calc((var(--windowHeight) - 14vw) / 5);
        }
      }
    }
  }

  .hideNav {
    div:not(:first-child) {
      visibility: hidden;
    }
  }

  body .container {
    margin-left: $sidebar;
    width: 84vw;
    padding-top: $sidebar;

    #about, #who, #responsibility, #outcome, #faq {
      display: block;
      padding-bottom: $sidebar;
      margin-top: -$sidebar;
    }

    .landing {
      padding: 0 3rem;
      align-items: center;
      height: calc(var(--windowHeight) - 32vw);

      h1 {
        top: -$sidebar / 2;
      }

      .landingEllipse {
        top: -2vw;
        left: -2.5vw;
        width: 34vw;
        height: 17vw;
      }

      #apply span {
        padding: 3vw 5vw;
      }
    }

    .overview {
      grid-template-columns: 15% 85%;
      grid-template-rows: auto;
      grid-template-areas: 
        "title title"
        "arrow detail";
      padding: 8vw;
    }

    .overviewTitle { 
      grid-area: title; 
      margin-bottom: $spacer3;
    }

    .overviewArrow { 
      grid-area: arrow; 

      img {
        width: 5vw;
      }

      ul {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;

        li:nth-child(1) { 
          img {
            margin-bottom: 3.6rem;
          }
        }
        li:nth-child(2) { 
          img {
            margin-bottom: calc(5vw + 3.6rem);
          }
        }
        li:nth-child(3) { 
          img {
            margin-bottom: 3.6rem;
          }
        }
        li:nth-child(4) { 
          img {
            margin-bottom: calc(5vw + 3.6rem);
          }
        }
      }
    }

    .overviewDetails { 
      grid-area: detail;

      li p {
        line-height: 5vw;
        margin-bottom: $spacer2;
      }
    }

    .about {
      position: relative;
      padding: 8vw;

      h2 {
        width: 50%;
        margin-bottom: $spacer4;
      }
    }

    .aboutDetails {
      grid-template-columns: 100%;

      img {
        position: absolute;
        top: 5vw;
        right: 8vw;
        width: 30%;
        margin-top: 0;
      }
    }

    &>.gradient {
      opacity: 1 !important;
    }

    .gradient span {
      font-size: 3.7vw;
    }

    .who {
      position: relative;
      padding: 8vw;

      img.hidden {
        display: block;
        position: absolute;
        right: 25%;
        top: 8vw;
        transform: rotate(90deg);
        width: 7vw;
      }
    }

    .responsibility {
      grid-template-columns: 50% 50%;
      grid-template-rows: 35vw;
      grid-template-areas:
        "header header"
        "one two"
        "three four";

      div:not(:first-child) {
        padding: 2rem;
      }
    }

    .responsibilityEllipse {
      top: -5vw;
      left: -3.5vw;
      width: 40vw;
      height: 20vw;
    }

    .outcome {
      position: relative;
      padding: 8vw; 
      overflow: hidden;

      h2 {
        margin-left: -8vw;
      }

      div:first-child {
        padding: 0 0 0 8vw;
      }

      li::before {
        margin-top: -2px;
        margin-left: calc(-3.5vw - 4rem);
      }
      
      div:last-child {
        display: none;
      }

      img.hidden {
        display: block;
        position: absolute;
        z-index: -1;
        top: -5vw;
        right: -10vw;
        width: 65%;
      }

      p.hidden {
        display: block;
        margin-left: -8vw;
        margin-bottom: $spacer2;
      }
    }

    .faq {
      padding: 8vw;
    }

    .faq-item {
      margin-bottom: $spacer3;
    }
    
    .faqDetails {
      grid-template-columns: 100%;
      margin-left: 7vw;

      .q:before {
        width: 3vw;
        height: 3vw;
        top: calc(3.5vw / 2);
        left: calc(-6vw - 1vw);
      }

      div:last-child div:last-child {
        margin: 0;
      }
    }

    .thankYou {
      padding: 8vw;
      grid-template-columns: 15% 85%;
      grid-template-rows: auto;
      grid-template-areas: 
        "title title"
        "arrow detail";
    }

    .footer {
      padding: 5vw 8vw;
      align-items: flex-end;
    }

    .signature {
      flex-direction: column;
      align-items: initial;

      span img {
        width: 2.5vw;
      }

      &> img {
        content: url("../images/designco.svg");
        margin-left: 0;
        width: 21vw;
      }
    }
  }
}

@media screen and (max-width: $lg) and (min-width: $sm + 1) {
  body {
    .sidebar {
      width: 10vw;
    }
  
    .container {
      margin-left: 10vw;
      width: 90vw;
    }
  }

  #nav {
    width: 30vw;

    .navHeader {
      margin: 0 3vw;
      height: calc(1.6vw + 4vw);

      #minimizeBtn {
        width: 1.6vw;
      }
    }

    .gradientTransition {
      height: calc(1.6vw + 4vw);
      line-height: calc(1.6vw + 4vw);

      a, span {
        padding: 2vw 3vw;
      }
    }
  }
}